import React, {FC, useEffect, useState} from 'react';
import {Stack, Box} from '@mui/material';
import {ESubcategory} from 'modules/theme/components/listing/cards/advertisementCard/enums/parameters/category/ESubcategory';
import {
    Title
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/title/Title';
import {
    Information
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/information/Information';
import {
    Description
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/description/Description';
import {
    Price
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/price/Price';
import {
    SellerPhoto
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/seller/SellerPhoto';
import {
    ListingLink
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/listingLink/ListingLink';
import {
    NameOfAdvertiser
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/agencyName/NameOfAdvertiser';
import {
    IListingUrl
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/listingUrl/IListingUrl';
import {
    IDevProject
} from 'modules/theme/components/listing/cards/advertisementCard/interfaces/advertisement/partials/devProject/IDevProject';
import {
    DevProjectLink
} from 'modules/theme/components/listing/cards/advertisementCard/components/card/partials/card/contentPart/common/devProject/DevProjectLink';

interface IContent {
    title: string;
    price: string;
    area?: number;
    isPro?: boolean;
    location: string;
    nameOfAdvertiser: string;
    unitPrice?: string;
    isPremium?: boolean;
    description: string;
    isSellerPro?: boolean;
    isAgencyProfi?: boolean;
    listingUrl?: IListingUrl;
    sellerPhotoUrl?: string;
    devProject?: IDevProject;
    subcategory: ESubcategory;
}

export const Content: FC<IContent> = ({
    title,
    area,
    price,
    location,
    isPremium,
    unitPrice,
    devProject,
    listingUrl,
    nameOfAdvertiser,
    subcategory,
    isSellerPro,
    description,
    isAgencyProfi,
    sellerPhotoUrl,
}) => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    return <Stack paddingY={1} direction="column" height="100%" justifyContent="space-between">

        <Stack direction="column" gap={1}>
            <Title title={title} isHighlighted={isAgencyProfi}/>

            <Information area={area} location={location} subcategory={subcategory}/>

            <Description description={description} showMoreText={isPremium}/>
        </Stack>
        <Stack direction="row">
            {(devProject && isClient) && <DevProjectLink devProject={devProject}/>}
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">

            <Stack direction="row" alignItems="end" gap={1.5}>
                <SellerPhoto photoUrl={sellerPhotoUrl} isPro={isSellerPro}/>
                <Box
                    height={35}
                    display='flex'
                    flexDirection='column'
                    justifyContent={listingUrl && isClient
                        ? 'center'
                        : 'flex-start'
                    }
                >
                    <NameOfAdvertiser nameOfAdvertiser={nameOfAdvertiser} />
                    {(listingUrl && isClient) && <ListingLink url={listingUrl.url} text={listingUrl.text}/>}
                </Box>
            </Stack>

            <Price price={price} unitPrice={unitPrice}/>
        </Stack>

    </Stack>;
};
